// RESPONSIVE NAV
var mainNav = function(menuList, menuBtn) {

  var s,
    GrNav = {

      settings: {
        menuList: $(menuList),
        menuBtn: $(menuBtn),
        windowSize: 960,
      },

      init: function() {
        s = this.settings;
        this.navClick();
        this.responsiveNav();
      },

      // slide the menu on click
      navClick: function() {
        s.menuBtn.on('click', function(evt) {
          evt.preventDefault();
          s.menuList.slideToggle(300);
        });
      },

      // mobile resize function
      responsiveNav: function() {
        $(window).resize(function() {
          var width = $(window).width();
          if (width < s.windowSize) {
            s.menuList.attr("style", "");
          }
        });
      },

    };

  GrNav.init();

};

mainNav('#js-nav', '#menu');

$('#nav-toggle').on('click', function() {
  $(this).toggleClass('is-active');
});

// SVG REPLACEMENT
// =============================================================================

(function() {
  if(!Modernizr.svg) {
    $('img[src*="svg"]').attr('src', function() {
      return $(this).attr('src').replace('.svg', '.png');
    });
  }
})();
